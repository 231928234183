import type { Experiments, Theme, Variant } from '../';
import {
  useExperiment,
  useExperimentTrackingData,
  useExperiments,
} from '../contexts/experiments';
import { useFeatureSwitches } from '../contexts/feature-switches';
import { useAppliedTheme, useThemeSettings } from '../contexts/theming';

const frameHooks: FrameHooks = {
  useExperiment: (name) => {
    return useExperiment(name, 'A');
  },
  useExperimentsLoadingState: () => {
    return { isLoading: useExperiments().loading };
  },
  useExperimentTrackingData: () => {
    return useExperimentTrackingData();
  },
  useFetchExperiments: () => {
    const { loading: isLoading, data: experiments = {} } = useExperiments();

    return { isLoading, experiments };
  },
  useFeatureSwitches: () => {
    const { data: featureSwitches } = useFeatureSwitches();

    return featureSwitches
      ? { loading: false, featureSwitches }
      : { loading: true };
  },
  useIsDarkMode: () => {
    return useAppliedTheme() === 'dark';
  },
  useTheme: () => {
    return useThemeSettings();
  },
};

/**
 * @deprecated Please use the new Hub APIs instead, see: https://brewery.preview.xing.io/hub
 */
export function useFrameHooks(): FrameHooks {
  return frameHooks;
}

type FrameHooks = {
  useExperiment: (experiment: string) => Variant;
  useExperimentsLoadingState: () => { isLoading: boolean };
  useExperimentTrackingData: () => {
    PropExperiment?: string;
    PropExperimentInfo?: string;
  };
  useFetchExperiments: () => {
    isLoading: boolean;
    experiments: Experiments;
  };
  useFeatureSwitches: () =>
    | { loading: true; featureSwitches?: undefined }
    | { loading: false; featureSwitches: string[] };
  /**
   * @deprecated: Use `useAppliedTheme() === 'dark'` and `import { useAppliedTheme } from '@xing-com/hub';` instead.
   */
  useIsDarkMode: () => boolean;
  /**
   * @deprecated: Use `useThemeSettings` via `import { useThemeSettings } from '@xing-com/hub';` instead, it works the same.
   */
  useTheme: () => [Theme, (theme: Theme) => void];
};
