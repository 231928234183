import { createContext, useContext } from 'react';

export const ThemingContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSettingsTheme(theme: Theme): void {
    //
  },
  useSettingsTheme(): Theme {
    return 'light';
  },
  useAppliedTheme(): AppliedTheme {
    return 'light';
  },
});

export function useAppliedTheme(): AppliedTheme {
  return useContext(ThemingContext).useAppliedTheme();
}

export function useThemeSettings(): [Theme, (theme: Theme) => void] {
  const { useSettingsTheme, setSettingsTheme } = useContext(ThemingContext);

  return [useSettingsTheme(), setSettingsTheme];
}

export const themes = ['dark', 'light', 'system'] as const;
export type Theme = (typeof themes)[number];
export type AppliedTheme = Exclude<Theme, 'system'>;
