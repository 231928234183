import { createContext, useContext } from 'react';

export type ErrorHandler = (
  error: Error | string,
  context?: Record<string, unknown> | undefined
) => void;

export const ErrorHandlerContext = createContext<ErrorHandler>(
  (error, context) => {
    // eslint-disable-next-line no-console
    console.error(error);

    if (context) {
      // eslint-disable-next-line no-console
      console.error('context:', context);
    }
  }
);

export function useErrorHandler(): ErrorHandler {
  return useContext(ErrorHandlerContext);
}
