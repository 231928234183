import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

export type ActivityCenterType = {
  isActivityCenterVisible: boolean;
  setIsActivityCenterVisible: Dispatch<SetStateAction<boolean>>;
  total: number;
  totalByEvents: Record<string, number | null>;
  setTotalByEvents: Dispatch<SetStateAction<Record<string, number | null>>>;
  origin: string;
  shouldOpenExternal: boolean;
  setShouldOpenExternal: Dispatch<SetStateAction<boolean>>;
};

export const ActivityCenterContext = createContext<ActivityCenterType>({
  isActivityCenterVisible: false,
  total: 0,
  totalByEvents: {},
  setTotalByEvents: () => undefined,
  setIsActivityCenterVisible: () => undefined, // redirect to checkout page?
  origin: 'unset',
  shouldOpenExternal: false,
  setShouldOpenExternal: () => undefined,
});

export const useActivityCenter = (): ActivityCenterType => {
  return useContext(ActivityCenterContext);
};

export const useIsActivityCenterVisible = (): boolean => {
  return useContext(ActivityCenterContext).isActivityCenterVisible;
};

export const useActivityCenterTotal = (): number => {
  return useContext(ActivityCenterContext).total;
};

export const useActivityCenterTotalByEvents = (): Record<
  string,
  number | null
> => {
  return useContext(ActivityCenterContext).totalByEvents;
};

export const useSetActivityCenterTotalByEvents = (): Dispatch<
  SetStateAction<Record<string, number | null>>
> => {
  return useContext(ActivityCenterContext).setTotalByEvents;
};

export const useSetActivityCenterVisible = (): Dispatch<
  SetStateAction<boolean>
> => {
  return useContext(ActivityCenterContext).setIsActivityCenterVisible;
};
