import { createContext, useContext } from 'react';

import type { AsyncHookResult } from '../helpers';

export const FeaturesContext = createContext({
  useFeatures(): AsyncHookResult<Features> {
    return { loading: false, data: {} };
  },
  useFeature<TFallback>(
    name: string,
    fallback: TFallback
  ): boolean | TFallback {
    return fallback;
  },
});

export function useFeatures(): AsyncHookResult<Features> {
  return useContext(FeaturesContext).useFeatures();
}

export function useFeature(name: string, fallback?: never): boolean | undefined;
export function useFeature<TFallback>(
  name: string,
  fallback: TFallback
): boolean | TFallback;
export function useFeature<TFallback>(
  name: string,
  fallback: TFallback
): boolean | TFallback {
  return useContext(FeaturesContext).useFeature(name, fallback);
}

export type Features = Partial<{
  isXingEmployee: boolean | null;
  isJobsPoster: boolean | null;
  isAdmasterUser: boolean | null;
  isBrandPageCollaborator: boolean | null;
  isBasic: boolean | null;
  isPremium: boolean | null;
  isExecutive: boolean | null;
  isSales: boolean | null;
  hasProJobsMembership: boolean | null;
  isCraUser: boolean | null;
  isSeatManagerAdmin: boolean | null;
  showProbusinessInNavigation: boolean | null;
  showUpsellHint: boolean | null;
  showJobSeekerBoneyardUpsellHint: boolean | null;
  showPremiumBoneyardUpsellHint: boolean | null;
  hasCoachProfile: boolean | null;
  isBrandManagerEditor: boolean | null;
  [name: string]: boolean | null;
}>;
