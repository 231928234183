// eslint-disable-next-line node/no-unpublished-import
import type { SetOptional } from 'type-fest';

import type {
  BasicEvent as NWTBasicEventV1,
  ExtendedEvent as NWTExtendedEventV1,
  MessageEvent as NWTMessageEventV1,
  SearchResultEvent as NWTSearchResultEventV1,
  ExperimentEvent as NWTExperimentEventV1,
  EcommerceEvent as NWTEcommerceEventV1,
  PerformanceEvent as NWTPerformanceEventV1,
  AiComponentEvent as NWTAiComponentEventV1,
} from './v1';

export type NWTBasicEvent = NWTBasicEventV1 & { eventSchema: 'basic' };
export type NWTExtendedEvent = NWTExtendedEventV1 & { eventSchema: 'extended' };
export type NWTMessageEvent = NWTMessageEventV1 & { eventSchema: 'message' };
export type NWTSearchResultEvent = NWTSearchResultEventV1 & {
  eventSchema: 'searchresult';
};
export type NWTExperimentEvent = NWTExperimentEventV1 & {
  eventSchema: 'experiment';
};
export type NWTEcommerceEvent = NWTEcommerceEventV1 & {
  eventSchema: 'ecommerce';
};
export type NWTPerformanceEvent = NWTPerformanceEventV1 & {
  eventSchema: 'performance';
};
export type NWTAiComponentEvent = NWTAiComponentEventV1 & {
  eventSchema: 'aicomponent';
};

type sendableNWTEvent =
  | NWTBasicEvent
  | NWTExtendedEvent
  | NWTMessageEvent
  | NWTSearchResultEvent
  | NWTExperimentEvent
  | NWTEcommerceEvent
  | NWTPerformanceEvent
  | NWTAiComponentEvent;

// we have to set the type as this is used to determine the event in the crate-tracking-lib
export type NWTEvent = SetOptional<
  sendableNWTEvent,
  'eventTimestamp' | 'channel' | 'schemaVersion'
> & {
  type: 'nwt';
};

// Update this manually when the schema changes
export const SCHEMA_VERSION = '1.20.0';
