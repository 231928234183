import { createContext, useContext } from 'react';

import type { AsyncHookResult } from '../helpers';

export const ExperimentsContext = createContext({
  useExperiments(): AsyncHookResult<Experiments> {
    return { loading: false, error: new Error('Missing experiments module') };
  },
  useExperiment<TFallback = undefined>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _name: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _fallback?: TFallback
  ): Variant | TFallback {
    return 'A';
  },
  useExperimentTrackingData(): ExperimentTrackingData {
    return {};
  },
});

export function useExperiments(): AsyncHookResult<Experiments> {
  return useContext(ExperimentsContext).useExperiments();
}

export function useExperiment<TFallback = undefined>(
  name: string,
  fallback?: TFallback
): Variant | TFallback {
  return useContext(ExperimentsContext).useExperiment(name, fallback);
}

export function useExperimentTrackingData(): ExperimentTrackingData {
  return useContext(ExperimentsContext).useExperimentTrackingData();
}

export type ExperimentType = 'user' | 'visitor';

export type RegisteredExperiment = {
  name: string;
  supportedVariants: ['A', ...Variant[]];
  experimentType: ExperimentType;
};

export type Experiment = RegisteredExperiment & {
  variant: Variant;
  status: 200 | 404 | 500;
};

export type Experiments = Record<string, Experiment>;

export type ExperimentTrackingData = {
  PropExperiment?: string;
  PropExperimentInfo?: string;
};

export type Variant =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';
