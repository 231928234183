import { createContext, useContext } from 'react';

import type { Features } from './features';
import type {
  LoginState,
  NetworkEfficiencyIndicator,
  XingId,
} from './viewer-data';

export type XingFrameContextType = {
  userConsentSettings?: Record<string, unknown>;
  cookies: Record<string, unknown>;
  /**
   * @deprecated Please use `useFeature()` instead, see: https://brewery.preview.xing.io/hub#features
   */
  features: Features;
  /**
   * @deprecated Please use `useFeatureSwitch()` instead, see: https://brewery.preview.xing.io/hub#feature-switches
   */
  featureSwitches: string[];
  /**
   * @deprecated Please use `useViewerData()` instead, see: https://brewery.preview.xing.io/hub#viewer-data
   */
  hashedUserId: string;
  /**
   * @deprecated Please use `useViewerData()` instead, see: https://brewery.preview.xing.io/hub#viewer-data
   */
  hasUser: boolean;
  /**
   * @deprecated Please use `useNotifications()` instead, see: https://brewery.preview.xing.io/hub#notifications
   */
  loadingAppStatsQuery: boolean;
  /**
   * @deprecated Please use `useViewerData().loading` instead, see: https://brewery.preview.xing.io/hub#viewer-data
   */
  loadingMainQuery: boolean;
  loggedOut: boolean;
  loginState: LoginState;
  /**
   * @deprecated Please use `useNotifications()` instead, see: https://brewery.preview.xing.io/hub#notifications
   */
  notifications?: Partial<Record<string, number>>;
  /**
   * @deprecated Please use `useNotificationsHandler().refresh` instead, see: https://brewery.preview.xing.io/hub#notifications
   */
  refreshNotifications?: () => void;
  /**
   * @deprecated Please use `useViewerData()` instead, see: https://brewery.preview.xing.io/hub#viewer-data
   */
  user: Partial<
    XingId & {
      unscrambledId: number;
      profileOccupation: {
        occupationOrg: string;
        occupationTitle: string;
      };
    }
  >;
  browserBarOpen: boolean;
  /**
   * @deprecated Please use `useViewerData()` instead, see: https://brewery.preview.xing.io/hub#viewer-data
   */
  networkEfficiencyIndicator?: NetworkEfficiencyIndicator;
  /**
   * @deprecated Please use `useViewerData()` instead, see: https://brewery.preview.xing.io/hub#viewer-data
   */
  willingnessToChangeJobs: number;
  internalPaths?: string[];
  pollInterval?: number;
};

export type ActiveItem =
  | 'home'
  | 'contacts'
  | 'messages'
  | 'premiumUpsell'
  | 'premium'
  | 'proJobs'
  | 'proBusiness'
  | 'jobs'
  | 'events'
  | 'content'
  | 'groups'
  | 'companies'
  | 'business'
  | 'services'
  | 'notificationCenterWeb' // deprecated, replaced by "supi"
  | 'supi'
  | undefined
  | null;

export const XingFrameContext = createContext<XingFrameContextType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  undefined as never
);

export function useFrameContext(): XingFrameContextType {
  const frameContext = useContext(XingFrameContext);

  if (!frameContext) throw new Error('Missing XingFrame module');

  return frameContext;
}
