export type AsyncHookResult<T> =
  | {
      loading: true;
      error?: undefined;
      data?: undefined;
    }
  | {
      loading: false;
      error: Error;
      data?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      data: T;
    };

export function asyncResult<T>(data?: T | undefined): AsyncHookResult<T> {
  return data ? { loading: false, data } : { loading: true };
}
