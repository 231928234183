import { createContext, useContext } from 'react';

export type Notifications = Partial<{
  ncWeb: number;
  unreadChats: number;
  [key: string]: number;
}>;

export type NotificationsHandler = {
  acknowledge(...badges: string[]): void;
  change(section: string, count: number): void;
  refresh(): void;
};

export const NotificationsContext = createContext<Notifications>({});

export const NotificationsHandlerContext = createContext<NotificationsHandler>({
  acknowledge: () => undefined,
  change: () => undefined,
  refresh: () => undefined,
});

export function useNotificationsHandler(): NotificationsHandler {
  return useContext(NotificationsHandlerContext);
}

export function useNotifications(): Notifications {
  return useContext(NotificationsContext);
}
