import type { PropsWithChildren } from 'react';
import { createContext, createElement, useContext } from 'react';

export type ReactLinkHandler = {
  Link: LinkComponent;
};

export const ReactLinkContext = createContext<ReactLinkHandler>({
  Link: ({ children, to, ...props }) => {
    return createElement('a', { href: to, ...props }, children);
  },
});

export function useReactLinkHandler(): ReactLinkHandler {
  return useContext(ReactLinkContext);
}

export function useLink(): LinkComponent {
  return useReactLinkHandler().Link;
}

export type LinkProps = PropsWithChildren<{
  to:
    | string
    | { pathname: string; hash?: string; search?: string; state?: unknown };
  [key: string]: unknown;
}>;

export type LinkComponent = React.ComponentType<LinkProps>;
