import { createContext, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type SetIsBottomBarEnabled = Dispatch<SetStateAction<boolean>>;

export type BottomBarEnabled = {
  isBottomBarEnabled: boolean;
  setIsBottomBarEnabled: SetIsBottomBarEnabled;
};

export const BottomBarEnabledContext = createContext<BottomBarEnabled>({
  isBottomBarEnabled: true,
  setIsBottomBarEnabled: () => undefined,
});

export function useIsBottomBarEnabled(): boolean {
  return useContext(BottomBarEnabledContext).isBottomBarEnabled;
}

export function useSetIsBottomBarEnabled(): SetIsBottomBarEnabled {
  return useContext(BottomBarEnabledContext).setIsBottomBarEnabled;
}
