import { createContext, useContext } from 'react';

export type OmView = {
  isOmViewVisible: boolean;
  omViewRef: React.RefObject<HTMLDivElement> | undefined;
  setIsOmViewVisible: (isOmViewVisible: boolean) => void;
};

export const OmViewContext = createContext<OmView>({
  isOmViewVisible: false,
  omViewRef: undefined,
  setIsOmViewVisible: () => undefined,
});

export const useOmViewVisible = (): boolean => {
  return useContext(OmViewContext).isOmViewVisible;
};

export const useSetIsOmViewVisible = (): ((
  isOmViewVisible: boolean
) => void) => {
  return useContext(OmViewContext).setIsOmViewVisible;
};

export const useOmViewRef = (): React.RefObject<HTMLDivElement> => {
  const omViewRef = useContext(OmViewContext).omViewRef;

  if (!omViewRef) {
    throw new Error('OmViewRef not found');
  }

  return omViewRef;
};
