import { createContext, useContext } from 'react';

import type { AsyncHookResult } from '../helpers';

export const FeatureSwitchesContext = createContext({
  useFeatureSwitches(): AsyncHookResult<FeatureSwitches> {
    return { loading: false, data: [] };
  },
  useFeatureSwitch<TFallback>(
    name: string,
    fallback: TFallback
  ): boolean | TFallback {
    return fallback;
  },
});

export function useFeatureSwitches(): AsyncHookResult<FeatureSwitches> {
  return useContext(FeatureSwitchesContext).useFeatureSwitches();
}

export function useFeatureSwitch(
  name: string,
  fallback?: never
): boolean | undefined;
export function useFeatureSwitch<TFallback>(
  name: string,
  fallback: TFallback
): boolean | TFallback;
export function useFeatureSwitch<TFallback>(
  name: string,
  fallback: TFallback
): boolean | TFallback {
  return useContext(FeatureSwitchesContext).useFeatureSwitch(name, fallback);
}

export type FeatureSwitches = string[];
